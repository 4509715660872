import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`This is part 2 of an ongoing series. `}<a parentName="em" {...{
          "href": "https://www.dashbot.io/2019/06/17/customer-service-chatbots-from-idea-to-launch/"
        }}>{`Find part 1 here`}</a>{`.`}</em></p>
    <p>{`Evaluating what support questions can be automated with a chatbot starts you off on the right foot to developing a `}<a parentName="p" {...{
        "href": "https://medium.com/@sprocompany/what-is-a-minimum-viable-product-and-how-to-build-an-mvp-for-your-startup-9a02c0d4a56a"
      }}>{`minimum viable product (MVP)`}</a>{`. Clearly defining ROI, which channels to implement, and use cases puts developers and product managers in the right frame of mind to create a project that customers will use and benefit from. As a continuation of the go-to-market strategy, it’s important to identify the best tools to build your customer service chatbot.`}</p>
    <div className="wp-block-image">
  <figure className="aligncenter">
    ![in-house or agency for customer service chatbot
    mvp](https://i.dashbot.io/wp-home/uploads/2019/10/21103753/image-1.jpg)
  </figure>
    </div>
### **In-house or agency?**
    <p>{`Now that it’s actually time to start building the customer service chatbot MVP, there are two main options to consider: in-house or agency. Some are going to want a larger role in the way the chatbot is built, and it’s worth mentioning that it’s easier to keep a close eye on the chatbot being built in-house. If there’s a very specific vision of the project, having the team close and accessible provides more control of the development. Instead of sending emails to an outside vendor and waiting for the routine scheduled meeting for an update, the in-house team can be easily reached by walking across the office. Less back-and-forth to communicate an idea means there’s a lower chance of wires getting crossed. In addition, all the insights gained from this chatbot project can be shared throughout the company and applied to future projects as well.`}</p>
    <p>{`If speed is a main objective for the project, hiring an agency to build the chatbot would ensure that development happens in a timely manner. Assembling a team and figuring out a workflow requires time, which might bottleneck the project before it even got started. Agencies are already staffed with experienced developers and designers, allowing you to bypass the process of finding each new hire. It’s also worth mentioning that agencies are typically the more cost-effective option for smaller companies that lack development teams.`}</p>
    <p><img parentName="p" {...{
        "src": "https://lh5.googleusercontent.com/pjxeEuMYdHp9oeMzZ8xz_8hXXEzsojMXDMNCIH_yfMRQHwOMbN-LP7jPbHWkKYQI80zi56_xiFYKJJhqjhfaUFrUEQ0aQJuk9Xck9texG7xrySNGmPlgHwOj9fYpsdSVFKYJR7gJ",
        "alt": "turning utterances to intents for customer service chatbot mvp"
      }}></img>{` ### `}<strong parentName="p">{`Choose an NLP engine`}</strong></p>
    <p>{`Building a chatbot– big or small– that understands human language instead of relying on conversation trees requires a Natural Language Processing (NLP) engine. The NLP engine will create a model tailored to your purpose, so the chatbot will be able to understand phrasing or vernacular that’s specific to your industry. Without it, a chatbot would be unable to extract the relevant information and ascertain meaning from an Utterance.`}</p>
    <p>{`NLP engines are abundant, so it won’t be hard to find one that will best fit each use case. If building for a Facebook Messenger bot, Facebook’s own `}<a parentName="p" {...{
        "href": "https://wit.ai/"
      }}>{`Wit.ai`}</a>{` is actively being developed and already part of the infrastructure. `}<a parentName="p" {...{
        "href": "https://dialogflow.com/"
      }}>{`Dialogflow`}</a>{`, another widely-used option, is Google’s solution to natural language understanding. It has 35 domains of knowledge available and supports voice interfaces as well. If you’re looking to build something within the Amazon platform, `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/lex/"
      }}>{`Amazon Lex`}</a>{` can create a chatbot in minutes with just a few phrases and has pre-built integrations with many services on the AWS platform. These options are free, so they’re good choices for MVP projects.`}</p>
    <p>{`In 2016, Microsoft introduced Language Understanding Intelligent Service, or `}<a parentName="p" {...{
        "href": "https://www.luis.ai/"
      }}>{`LUIS`}</a>{`. Some might want to integrate their customer service chatbot with an enterprise software, and LUIS is well-suited for that. `}<a parentName="p" {...{
        "href": "https://www.ibm.com/watson"
      }}>{`IBM Watson`}</a>{`, another enterprise solution, has a wide range of pre-built customer care and other common Intents. If your company is already using the Microsoft or IBM suite of products, these options would be an easy addition to begin chatbot development.`}</p>
    <p>{`Projects that benefit from a high level of customization would see a lot of value from using something like `}<a parentName="p" {...{
        "href": "https://rasa.com/"
      }}>{`RASA`}</a>{`, an open source machine learning tool. Since it’s not an out-of-the-box tool, there are a lot of opportunities to customize and fully integrate RASA into existing systems.`}</p>
    <h3><strong parentName="h3">{`Designing your conversations`}</strong></h3>
    <p>{`After deciding on an agency (or not) and an NLP engine, it’s time to start building content. This is an especially important step for customer service chatbots, since users will reach out for support on this platform. In order to lower escalation rates and ensure most users get the answers they’re looking for, build content around topics that customers are asking about on current channels.`}</p>
    <p>{`Determining what customers are asking will point the conversation designers in the right direction. In `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2019/06/17/customer-service-chatbots-from-idea-to-launch/"
      }}>{`part 1`}</a>{`, we discussed examining historical live agent logs to determine what support questions could be automated. That same data is also viable to inform design, providing insight to where customers need the most support. If there’s a high volume of logs, consider using a tool like `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/tour/tools/#phrase-clusters"
      }}>{`Phrase Clusters`}</a>{`. Phrase Clusters will group common messages together based on semantic meaning so designers will know which Intents users will trigger most often.`}</p>
    <p>{`It goes without saying that not every single Intent can be predicted before launch. No matter how much preparation goes into the chatbot, there are going to be some surprises once users start interacting with it. `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2019/09/10/customer-service-training-data/"
      }}>{`Optimizing the content `}</a>{`to meet user expectations will be key to ensuring high `}<a parentName="p" {...{
        "href": "https://www.hubspot.com/customer-satisfaction"
      }}>{`customer satisfaction`}</a>{` along with longevity of the project. In order to do that, examine user conversations using a conversational analytics tool to identify common unexpected Intents among transcripts.`}</p>
    <h3><strong parentName="h3">{`Launch is day one`}</strong></h3>
    <p>{`Keeping customers in mind at every step of the way will embed value in every aspect of the customer service chatbot. A thoughtfully made, tailored MVP designed specifically for your users will perform better than one developed impatiently. Picking the right NLP engine and Intents to create content for is a step in the right direction, but the work is far from over.`}</p>
    <p>{`The more a user interacts with a chatbot, the more expectations they’ll have. Constant optimization is key to maintaining or even increasing customer satisfaction. This requires a powerful analytics tool like Dashbot that provides a granular look into each conversation. There’s a very high chance of customers running into obstacles right after launch, and it’s helpful to have visibility into each conversation as to catch these pain points early on. Having the ability to track KPI’s such as escalation and `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2019/10/03/user-satisfaction-report/"
      }}>{`user satisfaction`}</a>{` from the start will go a long way toward taking a chatbot beyond just a MVP.`}</p>
    <p><em parentName="p">{`This is part 2 of Dashbot’s series on chatbot development. Stay tuned for part 3`}</em>{` `}<em parentName="p">{`and catch up on`}</em>{` `}<em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io/2019/06/17/customer-service-chatbots-from-idea-to-launch/"
        }}>{`part 1 here`}</a>{`.`}</em></p>
    <h2><div aria-hidden="true" className="wp-block-spacer" style={{
        "height": "100px"
      }}></div>{`About Dashbot`}</h2>
    <p><em parentName="p">{`Dashbot is an analytics platform for conversational interfaces that enables enterprises to increase satisfaction, engagement, and conversions through actionable insights and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support DialogFlow, Alexa, Google Assistant, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      